import { API, graphqlOperation, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { listVodEntries,listPrimarySliders, getVodEntry } from '@/graphql/queries';
import { getAWSStorageAsset } from '@/services/storage';
import Video from "video.js";
/**
 * Fetches slider items for a given category from the backend using GraphQL queries.
 * Also fetches the associated VOD (Video On Demand) entry and image assets.
 *
 * 
 */
export const fetchSliderItems = async (categoryType) => {
  try {
    const filter = { category: { contains: categoryType } };

    // Fetch slider items using GraphQL query
    const response = await API.graphql({
      query: listPrimarySliders,
      variables: { filter },
      authMode: GRAPHQL_AUTH_MODE.API_KEY
    });

    // Validate GraphQL response structure
    if (!response.data || !response.data.listPrimarySliders) {
      console.error("Data structure from GraphQL response is unexpected.");
      return [];
    }

    let sliderItems = response.data.listPrimarySliders.items;

    // Fetch associated assets (image, video) for each slider item
    sliderItems = await Promise.all(
      sliderItems.map(async (item) => {
        try {
          // Fetch image from storage
          if(item.imageFilename != " " || item.imageFilename.length > 0 ) {
          const imageAsset = await getAWSStorageAsset(`SlidingImage/${item.imageFilename[0]}`);
          item.image = imageAsset;
          }
          // Fetch associated VOD entry if exists
          if (item.content?.vodEntryId) {
            const vodEntryResponse = await API.graphql({
              ...graphqlOperation(getVodEntry, { id: item.content.vodEntryId }),
              authMode: GRAPHQL_AUTH_MODE.API_KEY
            });

            const fetchedVodEntry = vodEntryResponse.data.getVodEntry;
            if (fetchedVodEntry && fetchedVodEntry.hlsUrl) {
              item.videoHlsUrl = fetchedVodEntry.hlsUrl;
              //If video, saves video duration as a promise 
              getInitialVideoDuration(item.videoHlsUrl).then(data => item.videoDuration = data);
            }

          }
        } catch (innerError) {
          console.error("Error processing an item:", innerError);
        }
        return item;
      })
    );

    // Sort slider items by the 'order' field
    sliderItems.sort((a, b) => a.order - b.order);

    // Move the video item to the first position if it exists
    const videoIndex = sliderItems.findIndex(item => item.videoHlsUrl);
    if (videoIndex !== -1) {
      const [videoItem] = sliderItems.splice(videoIndex, 1);
      sliderItems.unshift(videoItem);
    }

    return sliderItems;

  } catch (error) {
    console.error("An error occurred while fetching slider items:", error);
    return [];
  }
};

//initializes video metadata to pull first item/video duration
const getInitialVideoDuration = async (url) => {

  return new Promise((resolve, reject) => {
      const player = document.createElement('video');
      const video = Video(player)
      video.src({ src: url, type: "application/x-mpegURL" });
      // console.log('player slider js', player)

      player.addEventListener('loadedmetadata', function() {
        resolve(this.duration)
      });

      player.addEventListener('error', function(){
        reject(new Error('Error retrieving video duration from metadata'))
      })
  })
};